<template>
    <div>
        <validation-observer v-if="object" ref="validate">
            <b-form class="form">
                <b-form-group>
                    <b-form-checkbox switch v-model="object.active">Aktivno</b-form-checkbox>
                </b-form-group>
                <b-form-group class="mt-1" label="Naslov">
                    <validation-provider #default="{ errors }" name="naslov" rules="required|max:120">
                        <b-form-input v-model="object.title" name="title"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group class="mt-1" label="Pogoji">
                    <validation-provider #default="{ errors }" name="pogoji" rules="required|max:15000">
                        <TextAreaEditor class="mt-1" v-model="object.content"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import {
        BFormInput,
        BFormCheckbox,
        BFormGroup,
        BForm
    } from 'bootstrap-vue'
    import TextAreaEditor from '@/views/Components/TextAreaEditor'
    export default {
        components: {
            TextAreaEditor,
            ValidationProvider,
            ValidationObserver,
            BFormInput,
            BFormCheckbox,
            BFormGroup,
            BForm
        },
        props: {
            model: {
                type: Object,
                default: null
            },
            type: {
                type: String,
                dafault: 'add'
            }
        },
        computed: {
            object: {
                get() {
                    return this.model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        data() {
            return {
                required 
            }
        },
        methods:{
        
        }
    }
</script>